/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.resources.LocalResourcePaintable
import com.meistercharts.resources.svg.SvgPath
import it.neckar.geometry.Size
import it.neckar.open.http.Url
import it.neckar.open.unit.other.px

/**
 * Resources for elektro meister
 */
@Deprecated("Use SVG paths instead")
object ElektroMeisterResources {
  val Switch: LocalResourcePaintable = LocalResourcePaintable(Url.relative("elektromeister/switch.png"), size = @px Size(25.0, 25.0))
  val Socket: LocalResourcePaintable = LocalResourcePaintable(Url.relative("elektromeister/Switching_001_socket.png"), size = @px Size(25.0, 25.0))
  val Bulb: LocalResourcePaintable = LocalResourcePaintable(Url.relative("elektromeister/Lights_bulb_001_on.png"), size = @px Size(25.0, 25.0))
  val Lights: LocalResourcePaintable = LocalResourcePaintable(Url.relative("elektromeister/Lights_pendant_001_100.png"), size = @px Size(25.0, 25.0))
}
