/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.fill
import com.meistercharts.canvas.paintable.AbstractPaintable
import com.meistercharts.canvas.paintable.AbstractPaintablePaintingVariables
import com.meistercharts.canvas.paintable.PaintablePaintingVariables
import com.meistercharts.color.Color
import it.neckar.geometry.Rectangle

/**
 * Paints an indicator where the paintable is anchored at
 */
class AnchorLocationIndicatorPaintable : AbstractPaintable() {
  override fun paintingVariables(): PaintablePaintingVariables {
    return paintingVariables
  }

  private var paintingVariables = object : AbstractPaintablePaintingVariables() {
    override fun performCalculation(paintingContext: LayerPaintingContext) {
      boundingBox = Rectangle.centered(10.0, 20.0)
    }
  }

  override fun paintAfterLayout(paintingContext: LayerPaintingContext, x: @Zoomed Double, y: @Zoomed Double) {
    println(".......")
    val gc = paintingContext.gc
    gc.fill(Color.red)
    gc.fillRect(paintingVariables.boundingBox)
  }
}
