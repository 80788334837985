/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.PaintingVariables
import com.meistercharts.annotations.Window
import com.meistercharts.annotations.Zoomed
import com.meistercharts.canvas.paintable.ObjectFit
import com.meistercharts.canvas.saved
import com.meistercharts.resources.LocalResourcePaintable
import it.neckar.geometry.Direction

/**
 * Paints the background image
 */
class ElektromeisterFloorImageLayer(
  val configuration: Configuration,
) : AbstractLayer() {

  override val type: LayerType = LayerType.Background

  override fun paintingVariables(): PaintingVariables? {
    return paintingVariables
  }

  private val paintingVariables = object : PaintingVariables {
    var originX: @Window Double = 0.0
    var originY: @Window Double = 0.0

    var width: @Zoomed Double = 0.0
    var height: @Zoomed Double = 0.0

    override fun calculate(paintingContext: LayerPaintingContext) {
      val gc = paintingContext.gc
      val chartCalculator = paintingContext.chartCalculator

      originX = chartCalculator.contentAreaRelative2windowX(0.5)
      originY = chartCalculator.contentAreaRelative2windowY(0.5)

      width = chartCalculator.contentAreaRelative2zoomedX(1.0)
      height = chartCalculator.contentAreaRelative2zoomedY(1.0)
    }
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc

    gc.saved {
      configuration.backgroundImage().paintInBoundingBox(
        paintingContext = paintingContext,
        x = paintingVariables.originX,
        y = paintingVariables.originY,
        width = paintingVariables.width,
        height = paintingVariables.height,
        anchorDirection = Direction.Center,
        objectFit = ObjectFit.Contain
      )
    }
  }

  /**
   * Configuration for the background image layer
   */
  class Configuration(
    var backgroundImage: () -> LocalResourcePaintable,
  ) {
  }
}
