/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.PasspartoutLayer
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.axis.ValueAxisWithOffsetLayer
import com.meistercharts.algorithms.layers.bind
import com.meistercharts.annotations.Domain
import com.meistercharts.canvas.layout.cache.DoubleMultiCache
import com.meistercharts.canvas.paintTextBox
import com.meistercharts.color.Color
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.configurableBooleanProvider
import com.meistercharts.demo.configurableColorPickerProvider
import com.meistercharts.demo.configurableDouble
import com.meistercharts.demo.configurableEnum
import com.meistercharts.demo.configurableFontProvider
import com.meistercharts.demo.configurableInsets
import com.meistercharts.demo.configurableInt
import com.meistercharts.model.Vicinity
import com.meistercharts.range.ValueRange
import it.neckar.geometry.Direction
import it.neckar.open.formatting.decimalFormat
import it.neckar.open.formatting.format
import it.neckar.open.kotlin.lang.enumEntries

/**
 * Very simple demo that shows how to work with a value axis layer
 */
class ValueAxisWithOffsetDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("3704522e-f024-459e-93c6-b079e478b720")
  override val name: String = "Value axis with offset"
  override val quality: DemoQuality = DemoQuality.Broken

  //language=HTML
  override val description: String = "<h3>Visualizes a value axis with offset</h3>"

  override val category: DemoCategory = DemoCategory.Axis

  //function MeisterChartsPlatform$armRenderLoop$o$requestNextFrame$lambda(this$0) {
  //  return function (relativeNowInMillis) {
  //    Meistercharts_getInstance().t4f_1.g9m(relativeNowInMillis);

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {
    return MeisterchartsDemo {

      val valueAxisWithOffsetLayer = ValueAxisWithOffsetLayer("Title", ValueRange.linear(-200.0, 200.0))

      meistercharts {
        configure {
          layers.addClearBackground()

          val passpartoutLayer = PasspartoutLayer {
            color = { Color("rgba(69, 204, 112, 0.25)") } // use something different from white so the size of the axis can be better grasped
          }
          layers.addLayer(passpartoutLayer)
          layers.addLayer(valueAxisWithOffsetLayer)

          layers.addLayer(MyValueAxisWithOffsetDebugLayer(valueAxisWithOffsetLayer))

          passpartoutLayer.configuration.bind(valueAxisWithOffsetLayer.configuration)

          declare {
            section("Layout")
          }

          configurableInt("Space for Digits", valueAxisWithOffsetLayer.configuration::spaceForDigits) {
            min = 2
            onChange {
              valueAxisWithOffsetLayer.configuration.ticksFormat = decimalFormat(it)
            }
          }

          configurableDouble("Offset area size", valueAxisWithOffsetLayer.configuration::offsetAreaSize) {
            max = 500.0
          }

          configurableDouble("Axis size", valueAxisWithOffsetLayer.configuration::size) {
            value = 150.0
            max = 500.0
          }

          configurableInsets("Axis margin", valueAxisWithOffsetLayer.configuration::margin) {
          }

          declare {
            section("Title")
          }

          configurableBooleanProvider("Show Title", valueAxisWithOffsetLayer.configuration::titleVisible) {
          }

          configurableDouble("Title Gap", valueAxisWithOffsetLayer.configuration::titleGap) {
            max = 20.0
          }

          declare {
            section("Axis Config")
          }

          configurableEnum("Tick Orientation", valueAxisWithOffsetLayer.configuration::tickOrientation, enumEntries()) {
            value = Vicinity.Outside
          }
          configurableEnum("Axis End", valueAxisWithOffsetLayer.configuration::axisEndConfiguration, enumEntries()) {
          }

          declare {
            section("Axis")
          }

          configurableColorPickerProvider("Line color", valueAxisWithOffsetLayer.configuration::lineColor) {
          }

          configurableDouble("Axis line width", valueAxisWithOffsetLayer.configuration::axisLineWidth) {
            max = 20.0
          }
          configurableDouble("Tick length", valueAxisWithOffsetLayer.configuration::tickLength) {
            max = 20.0
          }
          configurableDouble("Tick width", valueAxisWithOffsetLayer.configuration::tickLineWidth) {
            max = 20.0
          }
          configurableDouble("Tick Label Gap", valueAxisWithOffsetLayer.configuration::tickLabelGap) {
            max = 20.0
          }

          configurableColorPickerProvider("Background Color", passpartoutLayer.configuration::color) {
          }

          configurableFontProvider("Tick font", valueAxisWithOffsetLayer.configuration::tickFont) {
          }

          configurableFontProvider("Title font", valueAxisWithOffsetLayer.configuration::titleFont) {
          }
        }
      }
    }
  }
}


private class MyValueAxisWithOffsetDebugLayer(val valueAxisWithOffsetLayer: ValueAxisWithOffsetLayer) : AbstractLayer() {
  override val type: LayerType = LayerType.Content

  override fun paint(paintingContext: LayerPaintingContext) {
    val lastTicks: @Domain DoubleMultiCache = valueAxisWithOffsetLayer.paintingVariables().tickDomainValues

    val paintingVariables = valueAxisWithOffsetLayer.paintingVariables()

    val lines = mutableListOf<String>().also {
      it.add("Axis Start: ${paintingVariables.axisStart}")
      it.add("Axis End: ${paintingVariables.axisEnd}")
      it.add("Start Value: ${paintingVariables.startDomainValue}")
      it.add("End Value: ${paintingVariables.endDomainValue}")

      it.add("---")

      it.add("Last Ticks: ${lastTicks.size}")
      lastTicks.fastForEach { tick ->
        it.add(tick.format(10, 0))
      }

      it.add("---")

      it.add("Offset Ticks: ${paintingVariables.offsetTicks.size}")

      paintingVariables.offsetTicks.fastForEach { tick ->
        it.add(tick.format(10, 0))
      }
    }

    val gc = paintingContext.gc
    gc.translate(gc.width, 0.0)
    gc.paintTextBox(
      lines, Direction.TopRight,
      20.0,
    )
  }
}
