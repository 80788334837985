/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuid4
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.text.addText
import com.meistercharts.charts.lizergy.roofPlanning.ModuleSize
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningGestalt
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningModel
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsSampleModels
import com.meistercharts.charts.lizergy.stringsPlanning.RoofString
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.demo.configurableDouble
import com.meistercharts.demo.configurableEnum
import com.meistercharts.demo.configurableInsetsSeparate
import com.meistercharts.demo.configurableSizeSeparate
import com.meistercharts.demo.section
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.geometry.DirectionBasedBasePointProvider
import com.meistercharts.style.BoxStyle
import it.neckar.geometry.Direction
import it.neckar.open.kotlin.lang.asProvider
import it.neckar.open.kotlin.lang.enumEntries
import kotlin.math.roundToInt

/**
 *
 */
class PvStringsPlanningDemoDescriptor : MeisterchartsDemoDescriptor<() -> PvStringsPlanningModel> {
  override val uuid: Uuid = uuidFrom("8662cf4b-3596-4fc9-9151-d8c337385cb1")
  override val name: String = "Photovoltaics Strings Planning"
  override val category: DemoCategory = DemoCategory.Lizergy

  // insets (mm) no visual impact
  override val quality: DemoQuality = DemoQuality.Low
  override val variabilityType: VariabilityType = VariabilityType.Stable

  override val predefinedConfigurations: List<PredefinedConfiguration<() -> PvStringsPlanningModel>> = listOf(
    PredefinedConfiguration(uuidFrom("33b2648d-cdfd-4c6a-9bd5-68c5a46bd60f"), { PvStringsSampleModels.allTypes() }, "All"),
  )

  override fun prepareDemo(configuration: PredefinedConfiguration<() -> PvStringsPlanningModel>?): MeisterchartsDemo {
    requireNotNull(configuration) { "Configuration required" }

    val model: PvStringsPlanningModel = configuration.payload()

    return MeisterchartsDemo {
      meistercharts {

        val gestalt = PvStringsPlanningGestalt(model)
        gestalt.configure(this)

        this.configure {
          layers.addText({ _, _ ->
            listOf(gestalt.pvStringsPlanningLayer.uiState.toString())
          }) {
            boxStyle = BoxStyle.gray
            anchorDirection = Direction.TopLeft
            anchorPointProvider = DirectionBasedBasePointProvider(Direction.TopLeft)
            font = FontDescriptorFragment.XS.asProvider()
          }
        }

        configurableEnum("Mode", gestalt.pvStringsPlanningLayer.configuration::mode, enumEntries())

        section("Strings")

        declare {

          button("Add string") {
            val roofString = RoofString(uuid4(), uuidFrom("0b49441a-cbab-487a-a013-4d4147348674"))
            model.addRoofString(roofString)
            markAsDirty()
          }

        }

        section("Roof")

        configurableSizeSeparate("roof (mm)", model::roofSize) {
          max = 15_000.0
        }

        configurableInsetsSeparate("Insets (mm)", model::suggestedRoofInsets) {
          max = 1_000.0
        }

        section("Modules")

        configurableDouble("Module longer (mm)", model.modulesSize.longer.toDouble()) {
          max = 2_000.0

          onChange {
            model.modulesSize = ModuleSize(it.roundToInt(), model.modulesSize.shorter)
            markAsDirty()
          }
        }
        configurableDouble("Module shorter (mm)", model.modulesSize.shorter.toDouble()) {
          max = 2_000.0

          onChange {
            model.modulesSize = ModuleSize(model.modulesSize.longer, it.roundToInt())
            markAsDirty()
          }
        }

      }
    }
  }
}
