/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors


import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuid4
import com.benasher44.uuid.uuidFrom
import com.meistercharts.algorithms.layers.text.addText
import com.meistercharts.charts.lizergy.roofPlanning.ModuleSize
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofPlanningModel
import com.meistercharts.charts.lizergy.roofPlanning.PvRoofSampleModels
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningGestalt
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsPlanningModel
import com.meistercharts.charts.lizergy.stringsPlanning.PvStringsSampleModels
import com.meistercharts.charts.lizergy.stringsPlanning.RoofString
import com.meistercharts.charts.lizergy.stringsPlanning.StringIndex
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.demo.VariabilityType
import com.meistercharts.demo.configurableDouble
import com.meistercharts.demo.configurableEnum
import com.meistercharts.demo.configurableInsetsSeparate
import com.meistercharts.demo.configurableSizeSeparate
import com.meistercharts.demo.descriptors.PvStringsPlanningDemoDescriptor.PredefinedConfigurationPayload
import com.meistercharts.demo.section
import com.meistercharts.font.FontDescriptorFragment
import com.meistercharts.geometry.DirectionBasedBasePointProvider
import com.meistercharts.style.BoxStyle
import it.neckar.geometry.Direction
import it.neckar.open.kotlin.lang.asProvider
import it.neckar.open.kotlin.lang.enumEntries
import kotlin.math.roundToInt

/**
 *
 */
class PvStringsPlanningDemoDescriptor : MeisterchartsDemoDescriptor<() -> PredefinedConfigurationPayload> {
  override val uuid: Uuid = uuidFrom("8662cf4b-3596-4fc9-9151-d8c337385cb1")
  override val name: String = "Photovoltaics Strings Planning"
  override val category: DemoCategory = DemoCategory.Lizergy

  // insets (mm) no visual impact
  override val quality: DemoQuality = DemoQuality.Low
  override val variabilityType: VariabilityType = VariabilityType.Stable

  override val predefinedConfigurations: List<PredefinedConfiguration<() -> PredefinedConfigurationPayload>> = listOf(
    PredefinedConfiguration(uuidFrom("33b2648d-cdfd-4c6a-9bd5-68c5a46bd60f"), {
      val roofPlanningModel = PvRoofSampleModels.allTypes()
      val stringsPlanningModel = PvStringsSampleModels.getSimpleString(roofPlanningModel.moduleAreas)
      PredefinedConfigurationPayload(roofPlanningModel, stringsPlanningModel)
    }, "All"),
  )

  data class PredefinedConfigurationPayload(
    val roofPlanningModel: PvRoofPlanningModel,
    val stringsPlanningModel: PvStringsPlanningModel,
  )

  override fun prepareDemo(configuration: PredefinedConfiguration<() -> PredefinedConfigurationPayload>?): MeisterchartsDemo {
    requireNotNull(configuration) { "Configuration required" }

    val payload = configuration.payload()
    val stringsPlanningModel = payload.stringsPlanningModel
    val roofPlanningModel = payload.roofPlanningModel

    return MeisterchartsDemo {
      meistercharts {

        val gestalt = PvStringsPlanningGestalt(stringsPlanningModel, roofPlanningModel)
        gestalt.configure(this)

        this.configure {
          layers.addText({ _, _ ->
            listOf(gestalt.pvStringsPlanningLayer.uiState.toString())
          }) {
            boxStyle = BoxStyle.gray
            anchorDirection = Direction.TopLeft
            anchorPointProvider = DirectionBasedBasePointProvider(Direction.TopLeft)
            font = FontDescriptorFragment.XS.asProvider()
          }
        }

        configurableEnum("Mode", gestalt.pvStringsPlanningLayer.configuration::mode, enumEntries())

        section("Strings")

        declare {

          button("Add string") {
            val roofString = RoofString(
              uuid = uuid4(),
              moduleLayoutUuid = uuidFrom("0b49441a-cbab-487a-a013-4d4147348674"),
              inverterIndex = 0,
              mpptInputIndex = 0,
              previousModulesCount = 0,
              followingModulesCount = 0,
              stringIndex = StringIndex(0),
            )
            stringsPlanningModel.addRoofString(roofString)
            markAsDirty()
          }

        }

        section("Roof")

        configurableSizeSeparate("roof (mm)", roofPlanningModel::roofSize) {
          max = 15_000.0
        }

        configurableInsetsSeparate("Insets (mm)", roofPlanningModel::suggestedRoofInsets) {
          max = 1_000.0
        }

        section("Modules")

        configurableDouble("Module longer (mm)", roofPlanningModel.modulesSize.longer.toDouble()) {
          max = 2_000.0

          onChange {
            roofPlanningModel.modulesSize = ModuleSize(it.roundToInt(), roofPlanningModel.modulesSize.shorter)
            markAsDirty()
          }
        }
        configurableDouble("Module shorter (mm)", roofPlanningModel.modulesSize.shorter.toDouble()) {
          max = 2_000.0

          onChange {
            roofPlanningModel.modulesSize = ModuleSize(roofPlanningModel.modulesSize.longer, it.roundToInt())
            markAsDirty()
          }
        }

      }
    }
  }
}
