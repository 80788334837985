/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.PaintingVariables
import com.meistercharts.algorithms.layers.domainChartCalculator
import com.meistercharts.annotations.Window
import com.meistercharts.canvas.layout.cache.CoordinatesMultiCache
import com.meistercharts.canvas.layout.cache.tracePath
import com.meistercharts.color.ColorProvider
import com.meistercharts.color.withAlpha
import com.meistercharts.range.LinearValueRange
import com.meistercharts.style.Palette
import it.neckar.elektromeister.rest.RelativeToFloorOrigin
import it.neckar.elektromeister.rest.Room
import it.neckar.geometry.Coordinates
import it.neckar.open.collections.fastForEachWithIndex
import it.neckar.open.unit.si.mm

/**
 * Highlights the current room
 */
class ElektromeisterRoomHighlightLayer(
  val configuration: Configuration,
) : AbstractLayer() {
  override val type: LayerType = LayerType.Content

  private val paintingVariables = object : PaintingVariables {
    /**
     * Contains the points of polyline for each room
     */
    val roomPolylinePoints: @Window CoordinatesMultiCache = CoordinatesMultiCache()

    override fun calculate(paintingContext: LayerPaintingContext) {
      val calculator = paintingContext.chartSupport.domainChartCalculator(configuration.rangeX, configuration.rangeY)

      val activeRoom = configuration.activeRoom()
      if (activeRoom == null) {
        roomPolylinePoints.prepare(0)
        return
      }

      roomPolylinePoints.prepare(activeRoom.bounds.verticesCount)

      activeRoom.bounds.vertices().fastForEachWithIndex { vertexIndex, vertex: @mm @RelativeToFloorOrigin Coordinates ->
        @Window val windowX = calculator.domain2windowX(vertex.x)
        @Window val windowY = calculator.domain2windowY(vertex.y)
        roomPolylinePoints.set(vertexIndex, windowX, windowY)
      }
    }
  }

  override fun paintingVariables(): PaintingVariables? {
    return paintingVariables
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc

    gc.beginPath()
    gc.tracePath(paintingVariables.roomPolylinePoints)
    gc.closePath()

    gc.fill(configuration.roomBoundsFill())
    gc.fill()

    gc.lineWidth = configuration.roomBoundsLineWidth
    gc.stroke(configuration.roomBoundsStroke())
    gc.stroke()
  }


  class Configuration(
    val rangeX: @mm LinearValueRange,
    val rangeY: @mm LinearValueRange,

    /**
     * Returns the currently active room (if there is one)
     */
    val activeRoom: () -> Room?,
  ) {

    var roomBoundsStroke: ColorProvider = Palette.primaryColors[0]
    var roomBoundsLineWidth: Double = 3.0

    var roomBoundsFill: ColorProvider = Palette.primaryColors[0].withAlpha(0.2)
  }
}
