package it.neckar.elektromeister.rest.components

import it.neckar.financial.currency.Money

/**
 * Represents a "thermostat"
 */
data class Thermostat(
  override val id: ElectricalComponent.Id,
  val label: String,
  val price: Money,
) : ElectricalComponent
