package it.neckar.elektromeister.rest.components

import com.benasher44.uuid.Uuid
import it.neckar.financial.currency.Money
import it.neckar.uuid.ValueClassUuid

/**
 * Represents a "garage door opener"
 */
data class GarageDoorOpener(
  override val id: ElectricalComponent.Id,
  val label: String,
  val price: Money,
) : ElectricalComponent {
}
