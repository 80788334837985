package it.neckar.elektromeister.rest.components

import com.benasher44.uuid.Uuid
import it.neckar.uuid.ValueClassUuid
import it.neckar.uuid.randomUuid4

/**
 * Represents an electrical component that can be placed on a floor plan
 */
sealed interface ElectricalComponent {
  /**
   * The ID of the component
   */
  val id: Id

  /**
   * The ID for any [ElectricalComponent]
   */
  data class Id(override val uuid: Uuid) : ValueClassUuid {
    companion object {
      /**
       * Generates a random [Id]
       */
      fun random(): Id {
        return Id(randomUuid4())
      }
    }
  }
}
