package com.meistercharts.charts.lizergy.stringsPlanning

import com.benasher44.uuid.uuidFrom
import com.meistercharts.charts.lizergy.roofPlanning.ModuleIndex
import com.meistercharts.charts.lizergy.roofPlanning.UnusableArea
import it.neckar.geometry.Coordinates
import it.neckar.geometry.RightTriangleType.MissingCornerInFirstQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInFourthQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInSecondQuadrant
import it.neckar.geometry.RightTriangleType.MissingCornerInThirdQuadrant
import it.neckar.geometry.Size

/**
 *
 */
object PvStringsSampleModels {
  /**
   * A simple model that only contains modules in the grid
   */
  fun getSimpleGrid(): PvStringsPlanningModel {
    return PvStringsPlanningModel().also {
      it.addModuleArea()
    }
  }

  fun getGridWithHoles(): PvStringsPlanningModel {
    return PvStringsPlanningModel().also {
      it.addModuleArea()
      it.removeModules { module ->
        val gridLayout = module.modulePlacement
        gridLayout.moduleIndex.y % 3 == 1 &&
            gridLayout.moduleIndex.x % 3 == 1
      }
    }
  }

  fun onlyUnusableArea(): PvStringsPlanningModel {
    return PvStringsPlanningModel().also {
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(500.0, 750.0), size = Size(1000.0, 1400.0)))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(2300.0, 3500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFirstQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(7500.0, 2000.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInSecondQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(1900.0, 5300.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInThirdQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(8300.0, 4500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFourthQuadrant))
    }
  }

  fun realistic(): PvStringsPlanningModel {
    return PvStringsPlanningModel().also {
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(2300.0, 1750.0), size = Size(1000.0, 1400.0)))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(2300.0, 3500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFirstQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(7500.0, 2000.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInSecondQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(1900.0, 5300.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInThirdQuadrant))
      it.unusableAreas.add(UnusableArea(location = Coordinates.of(8300.0, 4500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFourthQuadrant))

      it.addModuleArea().also { moduleArea ->
        moduleArea.modules.remove(ModuleIndex(1, 1))
        moduleArea.modules.remove(ModuleIndex(1, 2))
      }
    }
  }

  fun allTypes(): PvStringsPlanningModel {
    return PvStringsPlanningModel().also { model ->
      model.unusableAreas.add(UnusableArea(location = Coordinates.of(1850.0, 3850.0), size = Size(1000.0, 1400.0)))
      model.unusableAreas.add(UnusableArea(location = Coordinates.of(2300.0, 1700.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFirstQuadrant))
      model.unusableAreas.add(UnusableArea(location = Coordinates.of(7500.0, 2000.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInSecondQuadrant))
      model.unusableAreas.add(UnusableArea(location = Coordinates.of(1900.0, 5300.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInThirdQuadrant))
      model.unusableAreas.add(UnusableArea(location = Coordinates.of(8300.0, 4500.0), size = Size(2300.0, 800.0), rightTriangleType = MissingCornerInFourthQuadrant))

      model.addModuleArea().also { moduleArea ->
        moduleArea.modules.remove(ModuleIndex(1, 1))
        moduleArea.modules.remove(ModuleIndex(2, 1))
        moduleArea.modules.remove(ModuleIndex(1, 2))
        moduleArea.modules.remove(ModuleIndex(2, 2))
      }

      val moduleArea = model.moduleAreas.moduleAreas.first()
      model.addRoofString(
        RoofString(
          uuid = uuidFrom("c8d9a8a2-6bf8-46b8-ab8f-1e6e058d649b"),
          roofUuid = uuidFrom("f30dfd8a-f8cf-48c6-97cc-b26a46c119e7"),
          initialModules = listOf(
            moduleArea.getModule(ModuleIndex(0, 0)),
            moduleArea.getModule(ModuleIndex(0, 1)),
          )
        )
      )
    }
  }
}
