/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demojs

import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.DemoQuality
import com.meistercharts.demo.DemoRunConfiguration
import it.neckar.commons.kotlin.js.debug
import it.neckar.commons.kotlin.js.exception.ConsoleJsErrorHandler
import it.neckar.commons.kotlin.js.exception.JsErrorHandler
import it.neckar.commons.kotlin.js.exception.JsErrorHandlerMultiplexer
import it.neckar.commons.kotlin.js.exception.errorHandler
import it.neckar.commons.kotlin.js.getBoolean
import it.neckar.commons.kotlin.js.getNumber
import it.neckar.commons.kotlin.js.urlSearchParams
import it.neckar.logging.LoggerFactory
import it.neckar.open.unit.si.ms
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.url.URLSearchParams

/**
 * Starts a single demo. This method is called from the `index.html` file.
 * It shows a single demo and - optionally - allows to configure it.
 */
@JsExport
@Suppress("unused") // public API
@JsName("startMeisterchartsDemo")
fun startMeisterchartsDemo(
  navigationContainer: HTMLElement,
  meisterchartsContainer: HTMLElement,
  descriptionContainer: HTMLElement,
  configurationPaneContainer: HTMLElement,
) {

  //Register the error handler
  JsErrorHandler.registerWindowErrorHandler(
    JsErrorHandlerMultiplexer(listOf(
      ConsoleJsErrorHandler,
      object : JsErrorHandler {
        var firstError = true

        override fun nullError(message: dynamic) {
          DemoRunnerHTMLSupport.displayNullError(message)
        }

        override fun error(throwable: Throwable) {
          DemoRunnerHTMLSupport.displayError(throwable)
        }
        override fun otherError(message: dynamic, error: Any) {
          DemoRunnerHTMLSupport.displayOtherError(message, error)
        }
      }
    ))
  )

  val params = window.urlSearchParams()
  val uuidFragment: String? = params.get("uuid")
  logger.info("startMeisterchartsDemo: uuid = $uuidFragment")
  val demoRunConfiguration = extractDemoRunConfiguration()
  val meisterchartsDemosJS = MeisterchartsDemosJS(navigationContainer, meisterchartsContainer, descriptionContainer, configurationPaneContainer, demoRunConfiguration)
  meisterchartsDemosJS.startDemoByUuid(uuidFragment)

}


/**
 * Extracts the demo run configuration from the provided parameters
 */
private fun extractDemoRunConfiguration(): DemoRunConfiguration {
  val params = URLSearchParams(window.location.search)

  val all = params.getBoolean("all")

  val minQuality: DemoQuality
  val minVisibility: DemoCategory.Visibility
  if (all == true) {
    minQuality = DemoQuality.Broken
    minVisibility = DemoCategory.Visibility.DevOnly
    logger.info("Showing all demos")
  } else {
    minQuality = params.extractMinQuality()
    minVisibility = params.extractMinVisibility()
  }


  @ms val initialTime = params.getNumber(DemoRunConfiguration.UrlParamNames.initialTime)
  @ms val timeProgressionStep = params.getNumber(DemoRunConfiguration.UrlParamNames.timeProgressionStep)
  @ms val elapsedTime = params.getNumber(DemoRunConfiguration.UrlParamNames.elapsedTime)

  return DemoRunConfiguration.of(minQuality, minVisibility, params.extractShowConfiguration(), params.extractShowNavigation(), params.extractShowDescription(), initialTime = initialTime, timeProgressionStep = timeProgressionStep, elapsedTime = elapsedTime)
}

private fun URLSearchParams.extractShowNavigation(): Boolean {
  return getBoolean(DemoRunConfiguration.UrlParamNames.showNavigationPane) ?: true
}

private fun URLSearchParams.extractShowConfiguration(): Boolean {
  return getBoolean(DemoRunConfiguration.UrlParamNames.showConfigurationPane) ?: true
}

private fun URLSearchParams.extractShowDescription(): Boolean {
  return getBoolean(DemoRunConfiguration.UrlParamNames.showDescriptionPane) ?: true
}

fun URLSearchParams.extractMinVisibility(): DemoCategory.Visibility {
  get(DemoRunConfiguration.UrlParamNames.minVisibility.name)?.let { minVisibilityParamString ->
    console.debug("minVisibility from url: $minVisibilityParamString")
    try {
      return DemoCategory.Visibility.valueOf(minVisibilityParamString)
    } catch (e: Exception) {
      console.warn("Could not parse $minVisibilityParamString as DemoCategory.Visibility due to ${e.message}")
    }
  }

  return DemoCategory.Visibility.Public
}

/**
 * Extracts the minQuality parameter from the URLSearchParams
 */
fun URLSearchParams.extractMinQuality(): DemoQuality {
  get(DemoRunConfiguration.UrlParamNames.minQuality.name)?.let { minQualityParamString ->
    console.debug("minQuality from url: $minQualityParamString")
    try {
      return DemoQuality.valueOf(minQualityParamString)
    } catch (e: Exception) { //https://youtrack.jetbrains.com/issue/KT-35116/Enum.valueOf-throws-inconsistent-exception-across-multiple-platforms
      console.warn("Could not parse $minQualityParamString as DemoQuality due to ${e.message}")
    }
  }

  return DemoQuality.High
}

private val logger = LoggerFactory.getLogger("com.meistercharts.demojs.DemoRunner")
