package it.neckar.open.math

import kotlin.math.abs
import kotlin.math.exp
import kotlin.math.max
import kotlin.math.min
import kotlin.random.Random

/**
 * A utility object for generating synthetic data and fitting sigmoidal models.
 */
object FittedSigmoid {

  /**
   * Generates synthetic data for sigmoidal fitting.
   *
   * The sigmoidal fitting process involves modelin g a relationship between an independent variable (often denoted as x)
   * and a dependent variable (often denoted as y or p) using a sigmoidal curve. Sigmoidal curves are commonly used
   * in various fields such as biology, economics, and machine learning to represent growth, saturation, or probability
   * distribution functions.
   *
   * In the context of this function, the independent variable 'x' represents a set of input values or predictors. These
   * values typically represent the range over which the sigmoidal curve will be fitted. For example, if modeling the
   * relationship between time and a biological response, 'x' could represent different time points at which the response
   * is measured.
   *
   * The dependent variable 'p' (often referred to as 'probability' in the context of logistic regression) represents
   * the corresponding output values or responses associated with the input values 'x'. In the context of logistic
   * regression or sigmoidal fitting, 'p' typically represents the probability of an event occurring given the input value
   * 'x'. For example, if modeling the probability of a disease occurrence based on a person's age, 'x' could represent
   * the age of individuals, and 'p' could represent the probability of the disease occurrence.
   *
   * This function generates synthetic data for sigmoidal fitting by simulating a set of 'x' values and corresponding 'p'
   * values. The 'x' values are generated within a specified range, and the 'p' values are computed using a logistic
   * function with added random noise to simulate variability in the data. The amount of noise, controlled by the 'variance'
   * parameter, determines the spread of the 'p' values around the sigmoidal curve.
   *
   * @param variance The variance of the synthetic data. Default is 0.1.
   * @return A pair of lists representing the generated synthetic data. The first list contains the independent variables (x),
   *         which represent the input values. The second list contains the dependent variables (p), which represent the output
   *         values or probabilities associated with the input values.
   */
  fun generateSyntheticData(variance: Double = 0.1): Pair<List<Double>, List<Double>> {
    val rng = Random(0) // For reproducibility
    var x = List(100) { it.toDouble() / 10 - 5 }
    val p = x.map { 1 / (1 + exp(-it)) + variance * rng.nextDouble() }
      //.map { min(1.0, max(0.0, it)) }
    x = List(100) { it.toDouble() / 10 }
    return Pair(x, p)
  }

  /**
   * Computes the logistic function value.
   * @param x The input value.
   * @param a The parameter for the horizontal asymptote.
   * @param b The growth rate parameter.
   * @param c The x-coordinate of the inflection point.
   * @return The value of the logistic function at the given input value.
   */
  fun logistic(x: Double, a: Double, b: Double, c: Double): Double {
    return a + (1 - a) / (1 + exp(-b * (x - c)))
  }

  /**
   * Fits a logistic model to the provided data.
   * @param x The list of independent variables (x).
   * @param p The list of dependent variables (p).
   * @return An array containing the fitted parameters [a, b, c] for the logistic model.
   */
  fun fitLogisticModel(x: List<Double>, p: List<Double>): DoubleArray {
    // Define the optimization function to minimize the objective function
    fun minimize(initialParams: DoubleArray, objective: (DoubleArray) -> Double): DoubleArray {
      val learningRate = 0.01
      val maxIterations = 10000
      val tolerance = 1e-6

      var params = initialParams.copyOf()
      var prevError = Double.POSITIVE_INFINITY

      for (iteration in 0 until maxIterations) {
        val currentError = objective(params)
        if (abs(currentError - prevError) < tolerance) {
          // Convergence criteria met
          break
        }
        prevError = currentError

        // Compute gradients
        val gradients = DoubleArray(params.size) { index ->
          val perturbedParams = params.copyOf()
          perturbedParams[index] += 1e-6 // Perturb parameter slightly
          val perturbedError = objective(perturbedParams)
          (perturbedError - currentError) / 1e-6
        }

        // Update parameters using gradient descent
        for (i in params.indices) {
          params[i] -= learningRate * gradients[i]
        }
      }

      return params
    }

    // Define the objective function to minimize (sum of squared errors)
    fun objective(params: DoubleArray): Double {
      val (a, b, c) = params
      return x.zip(p).sumOf { (xi, pi) ->
        val pred = logistic(xi, a, b, c)
        (pi - pred) * (pi - pred)
      }
    }

    // Use optimization algorithm to minimize the objective function
    val initialGuess = doubleArrayOf(0.5, 1.0, 0.0) // Initial guess for parameters
    return minimize(initialGuess, ::objective)
  }
}
