package it.neckar.elektromeister.rest

import it.neckar.elektromeister.rest.components.ElectricalComponent
import it.neckar.geometry.Coordinates
import it.neckar.open.unit.si.mm

/**
 * Represents a "placed component"
 */
data class PlacedElectricalComponent(
  /**
   * The component that has been placed
   */
  val component: ElectricalComponent,

  /**
   * The location where the component has been placed within a room
   */
  val location: @mm @RelativeToFloorOrigin Coordinates,

  //TODO maybe add size + rotation
) {
}
