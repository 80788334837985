/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.PaintingVariables
import com.meistercharts.algorithms.layers.domainChartCalculator
import com.meistercharts.annotations.Window
import com.meistercharts.canvas.layout.cache.CoordinatesMultiCache
import com.meistercharts.canvas.layout.cache.ObjectMultiCache
import com.meistercharts.canvas.paintLocation
import com.meistercharts.canvas.saved
import com.meistercharts.range.LinearValueRange
import com.meistercharts.resources.LocalResourcePaintable
import it.neckar.elektromeister.rest.PlacedElectricalComponent
import it.neckar.elektromeister.rest.components.CircuitBreaker
import it.neckar.elektromeister.rest.components.Doorbell
import it.neckar.elektromeister.rest.components.EthernetSocket
import it.neckar.elektromeister.rest.components.GarageDoorOpener
import it.neckar.elektromeister.rest.components.LightFixture
import it.neckar.elektromeister.rest.components.LightSwitch
import it.neckar.elektromeister.rest.components.MotionSensor
import it.neckar.elektromeister.rest.components.PowerDistributionBoard
import it.neckar.elektromeister.rest.components.SmokeDetector
import it.neckar.elektromeister.rest.components.SocketOutlet
import it.neckar.elektromeister.rest.components.Thermostat
import it.neckar.open.collections.fastForEachWithIndex
import it.neckar.open.unit.si.mm

/**
 * Paints all electrical components
 */
class ElektromeisterElectricalComponentsLayer(
  val configuration: Configuration,
) : AbstractLayer() {

  override val type: LayerType = LayerType.Content

  override fun paintingVariables(): PaintingVariables {
    return paintingVariables
  }

  private val paintingVariables = object : PaintingVariables {

    @Window
    val coordinatesCache = CoordinatesMultiCache()

    /**
     * Contains the paintables for each component
     */
    val paintablesCache = ObjectMultiCache<LocalResourcePaintable?>(null)

    override fun calculate(paintingContext: LayerPaintingContext) {
      val chartCalculator = paintingContext.chartSupport.domainChartCalculator(domainRangeX = configuration.rangeX, domainRangeY = configuration.rangeY)
      val electricalComponents = configuration.electricalComponents()

      coordinatesCache.prepare(electricalComponents.size)
      paintablesCache.prepare(electricalComponents.size)

      electricalComponents.fastForEachWithIndex { electricalComponentIndex: @ElectricalComponentIndex Int, electricalComponent: PlacedElectricalComponent ->
        val windowX = chartCalculator.domain2windowX(electricalComponent.location.x)
        val windowY = chartCalculator.domain2windowY(electricalComponent.location.y)

        val placedElectricalComponent = electricalComponents[electricalComponentIndex]

        //Store the coordinates
        coordinatesCache.set(electricalComponentIndex, windowX, windowY)


        val component = placedElectricalComponent.component
        val paintable: LocalResourcePaintable = when (component) {
          is SocketOutlet -> ElektroMeisterResources.Socket
          is LightSwitch -> ElektroMeisterResources.Switch
          is LightFixture -> ElektroMeisterResources.Lights

          is Doorbell -> ElektroMeisterResources.Bulb
          is CircuitBreaker -> ElektroMeisterResources.Bulb
          is EthernetSocket -> ElektroMeisterResources.Bulb
          is GarageDoorOpener -> ElektroMeisterResources.Bulb
          is MotionSensor -> ElektroMeisterResources.Bulb
          is PowerDistributionBoard -> ElektroMeisterResources.Bulb
          is SmokeDetector -> ElektroMeisterResources.Bulb
          is Thermostat -> ElektroMeisterResources.Bulb
        }

        //Store the type
        paintablesCache[electricalComponentIndex] = paintable
      }
    }
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc

    paintingVariables.coordinatesCache.fastForEachIndexed { electricalComponentIndex: @ElectricalComponentIndex Int, x, y ->
      val paintable = paintingVariables.paintablesCache[electricalComponentIndex]
      gc.saved {
        paintable?.paint(paintingContext, x, y)
      }
    }
  }

  override fun initialize(paintingContext: LayerPaintingContext) {
    super.initialize(paintingContext)
  }

  class Configuration(
    val rangeX: @mm LinearValueRange,
    val rangeY: @mm LinearValueRange,

    val electricalComponents: () -> List<PlacedElectricalComponent>,
  ) {
  }

  /**
   * An index annotation for the electrical components
   */
  @Retention(AnnotationRetention.SOURCE)
  @Target(
    AnnotationTarget.CLASS,
    AnnotationTarget.ANNOTATION_CLASS,
    AnnotationTarget.TYPE_PARAMETER,
    AnnotationTarget.PROPERTY,
    AnnotationTarget.FIELD,
    AnnotationTarget.LOCAL_VARIABLE,
    AnnotationTarget.VALUE_PARAMETER,
    AnnotationTarget.CONSTRUCTOR,
    AnnotationTarget.FUNCTION,
    AnnotationTarget.PROPERTY_GETTER,
    AnnotationTarget.PROPERTY_SETTER,
    AnnotationTarget.TYPE,
    AnnotationTarget.EXPRESSION,
    AnnotationTarget.FILE,
    AnnotationTarget.TYPEALIAS
  )
  @MustBeDocumented
  annotation class ElectricalComponentIndex
}
