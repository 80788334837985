package it.neckar.elektromeister.rest

import it.neckar.geometry.Coordinates
import it.neckar.open.unit.si.mm

/**
 * Represents a floor plan
 */
data class FloorPlan(
  /**
   * The rooms for this floor
   */
  val rooms: List<Room>,
  /**
   * All placed components (for all rooms)
   */
  val placedElectricalComponents: List<PlacedElectricalComponent>,
) {

  /**
   * Returns the first room that contains the provided coordinates
   */
  fun findRoom(coordinates: @mm Coordinates): Room? {
    return rooms.firstOrNull { room ->
      room.bounds.contains(coordinates)
    }
  }
}
