/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.demo.descriptors

import com.benasher44.uuid.Uuid
import com.benasher44.uuid.uuidFrom
import com.meistercharts.demo.DemoCategory
import com.meistercharts.demo.MeisterchartsDemo
import com.meistercharts.demo.MeisterchartsDemoDescriptor
import com.meistercharts.demo.PredefinedConfiguration
import com.meistercharts.elektromeister.ElektromeisterMeisterchartsModel
import com.meistercharts.elektromeister.ElektromeisterPocGestalt
import it.neckar.elektromeister.rest.FloorPlan
import it.neckar.elektromeister.rest.PlacedElectricalComponent
import it.neckar.elektromeister.rest.Room
import it.neckar.elektromeister.rest.components.ElectricalComponent
import it.neckar.elektromeister.rest.components.LightFixture
import it.neckar.elektromeister.rest.components.LightSwitch
import it.neckar.elektromeister.rest.components.SocketOutlet
import it.neckar.financial.currency.euro
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Polygon

/**
 *
 */
class ElektroMeisterPocGestaltDemoDescriptor : MeisterchartsDemoDescriptor<Nothing> {
  override val uuid: Uuid = uuidFrom("6a773c65-9068-4d7a-a5aa-303f818b8082")
  override val name: String = "Elektromeister PoC Gestalt"

  override val category: DemoCategory = DemoCategory.Elektromeister

  override fun prepareDemo(configuration: PredefinedConfiguration<Nothing>?): MeisterchartsDemo {

    val floorPlan = FloorPlan(
      rooms = listOf(
        Room(
          id = Room.Id.random(),
          label = "Waschküche/Technik",
          bounds = Polygon(
            listOf(
              Coordinates(540.0, 2710.0),
              Coordinates(540.0, 5810.0),
              Coordinates(3216.0, 5810.0),
              Coordinates(3216.0, 6090.0),
              Coordinates(6950.0, 6090.0),
              Coordinates(6950.0, 2710.0),
            )
          )
        ),
        Room(
          id = Room.Id.random(),
          label = "Flur",
          bounds = Polygon(
            listOf(
              Coordinates(540.0, 6028.0),
              Coordinates(540.0, 8507.0),
              Coordinates(3131.0, 8507.0),
              Coordinates(3131.0, 6028.0),
            )
          )
        ),
        Room(
          id = Room.Id.random(),
          label = "Keller",
          bounds = Polygon(
            listOf(
              Coordinates(3233.0, 6260.0),
              Coordinates(3233.0, 8508.0),
              Coordinates(6950.0, 8508.0),
              Coordinates(6950.0, 6260.0),
            )
          )
        ),
        Room(
          id = Room.Id.random(),
          label = "Hobbykeller",
          bounds = Polygon(
            listOf(
              Coordinates(540.0, 8675.0),
              Coordinates(540.0, 12880.0),
              Coordinates(6950.0, 12880.0),
              Coordinates(6950.0, 8675.0),
            )
          )
        ),
      ),
      placedElectricalComponents = listOf(
        PlacedElectricalComponent(
          component = LightSwitch(ElectricalComponent.Id.random(), "Lichtschalter", 34.71.euro),
          location = Coordinates(540.0, 3411.0)
        ),
        PlacedElectricalComponent(
          component = LightFixture(ElectricalComponent.Id.random(), "Deckenleuchte", 118.99.euro),
          location = Coordinates(940.0, 3711.0)
        ),
        PlacedElectricalComponent(
          component = SocketOutlet(ElectricalComponent.Id.random(), "Steckdose", 12.99.euro),
          location = Coordinates(6936.0, 4582.0)
        ),
      )
    )


    val model: ElektromeisterMeisterchartsModel = ElektromeisterMeisterchartsModel(
      floorPlan = floorPlan,
      floorPlanImage = ElektromeisterPocGestalt.sampleFloorPlanImage
    )

    return MeisterchartsDemo {
      meistercharts {
        val gestalt = ElektromeisterPocGestalt(
          ElektromeisterPocGestalt.Configuration {
            model
          }
        )
        gestalt.configure(this)

        configure {
        }
      }
    }
  }
}
