/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.AbstractLayer
import com.meistercharts.algorithms.layers.LayerPaintingContext
import com.meistercharts.algorithms.layers.LayerType
import com.meistercharts.algorithms.layers.PaintingVariables
import com.meistercharts.algorithms.layers.domainChartCalculator
import com.meistercharts.annotations.Window
import com.meistercharts.canvas.ChartSupport
import com.meistercharts.canvas.DirtyReason
import com.meistercharts.canvas.events.CanvasMouseEventHandler
import com.meistercharts.canvas.paintTextBox
import com.meistercharts.events.EventConsumption
import com.meistercharts.range.LinearValueRange
import com.meistercharts.style.BoxStyle
import it.neckar.elektromeister.rest.Room
import it.neckar.events.MouseDragEvent
import it.neckar.events.MouseMoveEvent
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.open.formatting.intFormat
import it.neckar.open.kotlin.lang.orNanIfNull
import it.neckar.open.kotlin.lang.setIfDifferent
import it.neckar.open.unit.number.MayBeNaN
import it.neckar.open.unit.si.mm

/**
 *
 */
class ElektromeisterMeasureLayer(
  val configuration: Configuration,
) : AbstractLayer() {

  override val type: LayerType = LayerType.Notification

  override fun paintingVariables(): MeasureLayerPaintingVariables {
    return paintingVariables
  }

  private val paintingVariables = object : MeasureLayerPaintingVariables {

    @MayBeNaN
    override var mouseLocationX: @Window Double = Double.NaN

    @MayBeNaN
    override var mouseLocationY: @Window Double = Double.NaN

    @MayBeNaN
    override var domainValueX: @mm Double = Double.NaN

    @MayBeNaN
    override var domainValueY: @mm Double = Double.NaN

    /**
     * The currently active room (where the mouse is located)
     */
    override var activeRoom: Room? = null

    fun isFinite(): Boolean {
      return domainValueX.isFinite() && domainValueY.isFinite()
    }

    override fun calculate(paintingContext: LayerPaintingContext) {
      val chartCalculator = paintingContext.chartSupport.domainChartCalculator(domainRangeX = configuration.rangeX, domainRangeY = configuration.rangeY)

      activeRoom = currentState.currentRoom

      mouseLocationX = currentState.currentMouseLocation?.x.orNanIfNull()
      mouseLocationY = currentState.currentMouseLocation?.y.orNanIfNull()

      if (mouseLocationX.isFinite() && mouseLocationY.isFinite()) {
        domainValueX = chartCalculator.window2domainX(mouseLocationX)
        domainValueY = chartCalculator.window2domainY(mouseLocationY)
      } else {
        domainValueX = Double.NaN
        domainValueY = Double.NaN
      }
    }
  }

  override fun paint(paintingContext: LayerPaintingContext) {
    val gc = paintingContext.gc

    if (paintingVariables.isFinite()) {
      gc.translateToBottomRight()

      val labels = buildList {
        paintingVariables.activeRoom?.let { room -> add(room.label) }
        add("${intFormat.format(paintingVariables.domainValueX)} mm / ${intFormat.format(paintingVariables.domainValueY)} mm")
      }
      gc.paintTextBox(labels, anchorDirection = Direction.BottomRight, boxStyle = configuration.boxStyle)
    }
  }

  override fun initialize(paintingContext: LayerPaintingContext) {
    super.initialize(paintingContext)

    //Update when translation has changed
    paintingContext.chartSupport.rootChartState.windowTranslationProperty.consume {
      recalculateState(paintingContext.chartSupport, currentState.currentMouseLocation)
    }

    paintingContext.chartSupport.rootChartState.zoomProperty.consume {
      recalculateState(paintingContext.chartSupport, currentState.currentMouseLocation)
    }
  }

  private fun recalculateState(chartSupport: ChartSupport, coordinates: @Window Coordinates?) {
    currentState::currentMouseLocation.setIfDifferent(coordinates) {
      chartSupport.markAsDirty(reason = DirtyReason.UserInteraction)
    }

    //calculate millimeters
    val newCurrentRoom = if (coordinates != null) {
      val calculator = chartSupport.domainChartCalculator(domainRangeX = configuration.rangeX, domainRangeY = configuration.rangeY)
      @mm val locationDomain = calculator.window2domain(coordinates)
      configuration.roomProvider(locationDomain)
    } else {
      null
    }

    currentState::currentRoom.setIfDifferent(newCurrentRoom) {
      chartSupport.markAsDirty(reason = DirtyReason.UserInteraction)
    }

    chartSupport.markAsDirty(reason = DirtyReason.UserInteraction)
  }

  override val mouseEventHandler: CanvasMouseEventHandler = object : CanvasMouseEventHandler {
    override fun onMove(event: MouseMoveEvent, chartSupport: ChartSupport): EventConsumption {
      recalculateState(chartSupport, event.coordinates)
      return super.onMove(event, chartSupport)
    }

    override fun onDrag(event: MouseDragEvent, chartSupport: ChartSupport): EventConsumption {
      recalculateState(chartSupport, event.coordinates)
      return super.onDrag(event, chartSupport)
    }
  }

  class Configuration(
    val rangeX: @mm LinearValueRange,
    val rangeY: @mm LinearValueRange,

    val roomProvider: (coordinates: @mm Coordinates) -> Room?,
  ) {

    var boxStyle: BoxStyle = BoxStyle.modernGray
  }

  private val currentState = object {
    /**
     * The measurement point
     */
    var currentMouseLocation: @Window Coordinates? = null
    var currentRoom: Room? = null
  }

  interface MeasureLayerPaintingVariables : PaintingVariables {
    val mouseLocationX: @Window Double
    val mouseLocationY: @Window Double
    val domainValueX: @mm Double
    val domainValueY: @mm Double

    var activeRoom: Room?
  }

  companion object {
    private val logger: Logger = LoggerFactory.getLogger("com.meistercharts.elektromeister.ElektromeisterMeasureLayer")
  }
}
