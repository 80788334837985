/**
 * Copyright 2023 Neckar IT GmbH, Mössingen, Germany
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
package com.meistercharts.elektromeister

import com.meistercharts.algorithms.layers.Layers.PaintingOrder
import com.meistercharts.algorithms.layers.addClearBackground
import com.meistercharts.algorithms.layers.debug.addPaintInfoDebug
import com.meistercharts.canvas.FixedContentAreaSize
import com.meistercharts.canvas.ImmediateWindowSizeBindingStrategy
import com.meistercharts.charts.AbstractChartGestalt
import com.meistercharts.model.Insets
import com.meistercharts.resize.KeepCenterOnWindowResize
import com.meistercharts.resources.LocalResourcePaintable
import com.meistercharts.zoom.FittingInContentViewportAspectRatio
import it.neckar.geometry.AxisOrientationY
import it.neckar.geometry.AxisSelection
import it.neckar.geometry.Coordinates
import it.neckar.geometry.Direction
import it.neckar.geometry.Size
import it.neckar.open.http.Url
import it.neckar.open.provider.SizedProvider
import it.neckar.open.unit.si.mm

/**
 * PoC for Elektromeister.
 *
 * ContentArea: in pixels - matches the size of the floor plan image
 */
class ElektromeisterPocGestalt(
  val configuration: Configuration,
  additionalConfiguration: Configuration.() -> Unit = {},
) : AbstractChartGestalt() {

  val floorPlanPaintable: LocalResourcePaintable = LocalResourcePaintable(configuration.model().floorPlanImage.url)

  val floorImageLayer: ElektromeisterFloorImageLayer = ElektromeisterFloorImageLayer(ElektromeisterFloorImageLayer.Configuration { floorPlanPaintable })

  val measureLayer: ElektromeisterMeasureLayer = ElektromeisterMeasureLayer(
    ElektromeisterMeasureLayer.Configuration(
      rangeX = configuration.model().floorPlanImage.rangeX,
      rangeY = configuration.model().floorPlanImage.rangeY,
      roomProvider = { coordinates: @mm Coordinates ->
        configuration.model().floorPlan.findRoom(coordinates)
      }
    )
  )

  val roomGrayBackgroundLayer = ElektromeisterRoomGrayBackgroundLayer(
    configuration = ElektromeisterRoomGrayBackgroundLayer.Configuration(
      rangeX = configuration.model().floorPlanImage.rangeX,
      rangeY = configuration.model().floorPlanImage.rangeY,
      rooms = SizedProvider.forList(configuration.model().floorPlan.rooms)
    )
  )

  val roomHighlightLayer: ElektromeisterRoomHighlightLayer = ElektromeisterRoomHighlightLayer(
    ElektromeisterRoomHighlightLayer.Configuration(
      rangeX = configuration.model().floorPlanImage.rangeX,
      rangeY = configuration.model().floorPlanImage.rangeY,
      activeRoom = { measureLayer.paintingVariables().activeRoom }
    )
  )

  val electricalComponentsLayer: ElektromeisterElectricalComponentsLayer = ElektromeisterElectricalComponentsLayer(
    ElektromeisterElectricalComponentsLayer.Configuration(
      rangeX = configuration.model().floorPlanImage.rangeX,
      rangeY = configuration.model().floorPlanImage.rangeY,
      electricalComponents = { configuration.model().floorPlan.placedElectricalComponents }
    )
  )

  init {
    configuration.additionalConfiguration()

    configureBuilder { builder ->
      builder.windowSizeBindingStrategy = ImmediateWindowSizeBindingStrategy
      builder.enableZoomAndTranslation = true

      builder.zoomAndTranslationConfiguration {
        translateAxisSelection = AxisSelection.Both
        zoomWithoutModifier()
      }

      builder.zoomAndTranslationModifier {
        contentAlwaysBarelyVisible()
      }

      builder.contentAreaSizingStrategy = FixedContentAreaSize {
        configuration.model().floorPlanImage.sizeInPixels
      }

      builder.zoomAndTranslationDefaults = FittingInContentViewportAspectRatio(alignment = { Direction.TopCenter })
    }

    configure {
      chartSupport.rootChartState.contentViewportMargin = Insets.of(50.0, 150.0, 20.0, 30.0)

      chartSupport.rootChartState.axisOrientationY = AxisOrientationY.OriginAtTop
      chartSupport.windowResizeBehavior = KeepCenterOnWindowResize

      layers.addClearBackground()

      //val backgroundLayer = BackgroundImageLayer(
      //  configuration = BackgroundImageLayer.Configuration(
      //    backgroundImage = LocalResourcePaintable(data)
      //  )
      //)
      //layers.addLayer(backgroundLayer)

      layers.addLayer(floorImageLayer)
      layers.addLayer(roomGrayBackgroundLayer)

      /**
       * The [measureLayer] must be layout first but painted after the [roomHighlightLayer]
       */
      @PaintingOrder val roomPaintingIndex = layers.addLayer(roomHighlightLayer)
      layers.addLayer(electricalComponentsLayer)
      layers.addLayerAt(measureLayer, roomPaintingIndex + 1, roomPaintingIndex) //layout before roomHighlightLayer

      layers.addPaintInfoDebug()
    }
  }

  class Configuration(
    /**
     * Returns the current floor plan that is displayed
     */
    val model: () -> ElektromeisterMeisterchartsModel,
  )

  companion object {
    /**
     * Sample image width in pixels
     */
    val sampleFloorPlanImage: FloorPlanImage = FloorPlanImage(
      url = Url.relative("elektromeister/floor-sample1.png"),
      sizeInPixels = Size(2028, 3822),
      /**
       * The ratio for pixel to meter for this image.
       *
       * Measured with a ruler:
       * * 752 pixels = 3.18 m
       */
      pixels2mm = 1.0 / 752 * (3.18 * 1000)
    )
  }
}
